<template>
  <div class="about-wrapper">
    <div class="about-team-wrap hidden-xs-only">
      <div class="about-item-box">
        <el-row :gutter="70">
          <!-- <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
            <div class="about-item mb110 wow animate__fadeInUp">
              <div class="about-card">
                <img class="team-img" src="../assets/img/team/about3.png" alt="team">
                <div class="team-name">Dr. Ooi</div>
                <div class="team-position">Direktur Utama</div>
              </div>
              <div class="info-btn-card">
                <div class="info-btn">Profile</div>
                <el-collapse-transition>
                  <div class="about-info">
                    <div class="about-info-title">Dr. Ooi</div>
                    <div class="about-info-content">Dr. Ooi memperoleh gelar Doktor di bidang Administrasi Bisnis dan manajemen dari University of outh Australia. Dia berperan penting dalam memulai perusahaan dan mengarahkan operasi dan rencana strategisnya. Dia membawa lebih dari 20 tahun pengalaman manajemen risiko dan kepatuhan dalam kepemimpinannya yang panjang dan berperan sebagai visioner dalam mengawasi strategi bisnis, pengembangan bisnis, alur bisnis, operasi bisnis, dan dukungan di seluruh wilayah Asia-Pasifik dari perusahaan solusi perbankan yang terdaftar di NASDAD. Sebelum ini, ia adalah seorang akuntan staf untuk organisasi asuransi kesehatan terbesar di amerika serikat dan menjadi seorang analis keuangan untuk bank tabungan A.S. di Los Angeles selama bertahun-tahun.</div>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
          </el-col> -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <div class="about-item mb110 wow animate__fadeInUp">
              <div class="about-card">
                <img class="team-img" src="../assets/img/team/about2.png" alt="team">
                <div class="team-name">Arnoldyth Rodes Medo</div>
                <div class="team-position">Direktur Utama</div>
              </div>
              <div class="info-btn-card">
                <div class="info-btn">Profile</div>
                <div class="about-info">
                  <div class="about-info-title">Arnoldyth Rodes Medo</div>
                  <div class="about-info-content">Arnold memperoleh gelar Sarjana Ekonomi dari Universitas Muhammadiyah Yogyakarta, jurusan Akuntansi. Beliau memiliki pengalaman dari beberapa Lembaga Keuangan baik dalam maupun luar negeri sejak tahun 2012 dan juga merupakan sosok pionir dalam dunia Fintech di Indonesia. Hingga saat ini, beliau memfokuskan keahliannya pada beberapa bidang seperti Risk Management, Internal Audit and Fraud Investigation, Sales and Marketing, Market Analysis dan Target Market Analysis.</div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <div class="about-item mb110 wow animate__fadeInUp">
              <div class="about-card">
                <img class="team-img" src="../assets/img/team/about1.png" alt="team">
                <div class="team-name">Satriya Putra</div>
                <div class="team-position">Direktur SDM</div>
              </div>
              <div class="info-btn-card">
                <div class="info-btn">Profile</div>
                <div class="about-info">
                  <div class="about-info-title">Satriya Putra</div>
                  <div class="about-info-content">Satriya memperoleh gelar Sarjana Ekonomi dari Universitas Trisakti, Jakarta, jurusan Manajemen. Beliau mempunyai karir cemerlang sebagai Direktur di beberapa perusahaan konsultan bisnis yang berkecimpung di bidang risk management dan Manajemen Informasi Teknologi dengan klien-kiennya baik dari perbankan maupun dari perusahaan jasa keuangan lainnya.</div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <div class="about-item mb110 wow animate__fadeInUp">
              <div class="about-card">
                <img class="team-img" src="../assets/img/team/about4.jpg" alt="team">
                <div class="team-name">Alan Yazid Ali</div>
                <div class="team-position">Komisaris</div>
              </div>
              <div class="info-btn-card">
                <div class="info-btn">Profile</div>
                <el-collapse-transition>
                  <div class="about-info">
                    <div class="about-info-title">Alan Yazid Ali</div>
                    <div class="about-info-content">Alan Yazid Ali memperoleh gelar Master di bidang MIS, Bisnis International, dan Manajemen Operasi dari Bowling Green State University, Ohio, America. Dia berperan penting dalam memulai perusahaan dan mengarahkan operasi serta rencana strategisnya. Dia membawa lebih dari 20 tahun pengalaman manajemen risiko dan kepatuhan dalam kepemimpinannya yang panjang dan memainkan peran visioner dalam mengawasi strategi bisnis, pengembangan bisnis, arus bisnis, operasi bisnis, dan dukungan di PT Bank Ekspor Indonesia (persero).Susunan Pengurus Perusahaan berdasarkan Akta Pernyataan Keputusan Rapat PT Smartec Teknologi Indonesia Nomor 6 tanggal 8 September 2022 dan telah mendapat diberitahukan kepada Kementerian Hukum dan HAM melalui surat Penerimaan Pemberitahuan Perubahan Data Perseroan Nomor AHU-AH.01.09-0054861</div>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
          </el-col>
        </el-row>       
      </div>
    </div>
    <div class="m-about-wrap hidden-sm-and-up">
      <div class="m-about-item-box">
        <!-- <div class="m-about-card" v-if="activeTeam == 1">
          <img class="m-team-img" src="../assets/img/team/m-about3.png" alt="m-about">
          <img class="m-next-btn" src="../assets/img/team/m-next.png" alt="m-next" @click="nextTeam(1)">
          <div class="m-team-name">Dr. Ooi</div>
          <div class="m-team-position">Direktur Utama</div>
          <div class="m-team-btn" @click="profile(0)">Profile</div>
        </div> -->
        <div class="m-about-card" v-if="activeTeam == 2">
          <img class="m-team-img" src="../assets/img/team/m-about2.png" alt="m-about">
          <img class="m-next-btn" src="../assets/img/team/m-next.png" alt="m-next" @click="nextTeam(2)">
          <div class="m-team-name">ARNOLDYTH RODES MEDO</div>
          <div class="m-team-position">Direktur Utama</div>
          <div class="m-team-btn" @click="profile(1)">Profile</div>
        </div>
        <div class="m-about-card" v-if="activeTeam == 3">
          <img class="m-team-img" src="../assets/img/team/m-about1.png" alt="m-about">
          <img class="m-next-btn" src="../assets/img/team/m-next.png" alt="m-next" @click="nextTeam(3)">
          <div class="m-team-name">SATRIYA PUTRA</div>
          <div class="m-team-position">Direktur SDM</div>
          <div class="m-team-btn" @click="profile(2)">Profile</div>
        </div>
        <div class="m-about-card" v-if="activeTeam == 4">
          <img class="m-team-img" src="../assets/img/team/about4.jpg" alt="m-about">
          <img class="m-next-btn" src="../assets/img/team/m-next.png" alt="m-next" @click="nextTeam(4)">
          <div class="m-team-name">Alan Yazid Ali</div>
          <div class="m-team-position">Komisaris</div>
          <div class="m-team-btn" @click="profile(3)">Profile</div>
        </div>
      </div>
    </div>
    <el-dialog
      class="m-dialog-box"
      :visible.sync="dialogVisible"
      width="90%">
      <div class="m-dialog">
        <img class="m-close-btn" @click="dialogVisible = false" src="../assets/img/team/close-dialog.png" alt="close">
        <div class="m-dialog-name">{{ mName }}</div>
        <div class="m-dialog-content">{{ mContent }}</div>
      </div>
    </el-dialog>
    <div class="laporan-wrap">
      <div class="laporan-card">
        <div class="laporan-title wow animate__fadeInLeft">Laporan</div>
        <div class="keuangan wow animate__fadeInLeft">Keuangan</div>
        <div class="smartec wow animate__fadeInLeft">Setiap tahun PT. Smartec Teknologi Indonesia akan selalu melaporkan kondisi keuangannya dan melakukan audit berkala.</div>
        <div class="lihat wow animate__fadeInLeft">Lihat laporan keuangan kami di sini:</div>
        <div class="financial-report-card">
          <el-row :gutter="70">
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="financial-item wow animate__fadeInUp" @click="show('2020')">
                <div class="financial-item-title">Laporan keuangan</div>
                <div class="financial-item-year">2020 <img src="../assets/img/team/chakanyan.png"></div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="financial-item wow animate__fadeInUp" @click="show('2021')">
                <div class="financial-item-title">Laporan keuangan</div>
                <div class="financial-item-year">2021 <img src="../assets/img/team/chakanyan.png"></div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="financial-item wow animate__fadeInUp" @click="show('2022')">
                <div class="financial-item-title">Laporan keuangan</div>
                <div class="financial-item-year">2022 <img src="../assets/img/team/chakanyan.png"></div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="financial-item wow animate__fadeInUp" @click="show('2023')">
                <div class="financial-item-title">Laporan keuangan</div>
                <div class="financial-item-year">2023 <img src="../assets/img/team/chakanyan.png"></div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="financial-table-card">
          <el-table
            class="financial-table"
            :data="tableData"
            stripe
            style="width: 100%">
            <el-table-column width="70px" prop="id" />
            <el-table-column
              prop="Nama"
              label="Nama Kegiatan" />
            <el-table-column
              align="center"
              prop="Tempat"
              label="Tempat" />
            <el-table-column
              align="right"
              prop="Tanggal"
              label="Tanggal Kegiatan" />
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import info from '@/utils/options'
import {WOW} from 'wowjs'
export default {
  data() {
    return {
      tableData: [
        { id: 1,
          Nama: '"Mengakses Layanan Pinjam Meminjam Berbasis Teknologi untuk UMKM Bersama Dinas Koperasi dan UMKM Kota Medan"',
          Tempat: 'Hotel Grand Aston, Medan',
          Tanggal: '13 Februari 2020'},
        { id: 2,
          Nama: '"Inovasi Keuangan Startup Fintech Indonesia Bersama Mahasiswa/i Universitas Bengkulu"',
          Tempat: 'Universitas Bengkulu',
          Tanggal: '14 Februari 2020'},
        { id: 3,
          Nama: '"Menjembatani Kebutuhan Pembiayaan Pengusaha Milenial Bersama HIPMI Kepulauan Riau"',
          Tempat: 'Hotel Grand Aston, Medan',
          Tanggal: '20 Februari 2020'},
        { id: 4,
          Nama: '"Fineast Kupang Bersama AFPI dan OJK"',
          Tempat: 'Hotel Aston, Kupang',
          Tanggal: '27-28 Februari 2020'},
        { id: 5,
          Nama: '"Ekosistem Fintech"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '07 Desember 2020'},
        { id: 6,
          Nama: '"Landscape Fintech Indonesia"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '08 Desember 2020'},
        { id: 7,
          Nama: '"Mengakses Keuangan Alternatif E-Commerce"',
          Tempat: 'Tempat: Kuningan, Jakarta (via zoom meeting)	',
          Tanggal: '14 Desember 2020'},
        { id: 8,
          Nama: '"Aspek-Aspek Legal P2P Lending"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '15 Desember 2020'},
        {
          id: 9,Nama: '"Managing your Budget for Individual Business"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '25 Januari 2021'},
        { id: 10,
          Nama: '"How to Manage your Personal Finance"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '9 Februari 2021'},
        { id: 11,
          Nama: '"Press Conference - Peran Fintech dalam Pemulihan Ekonomi Nasional"',
          Tempat: 'Tempat: Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '23 Februari 2021'},
        { id: 12,
          Nama: '"Artificial Intelligence in Alternative Credit Scoring"',
          Tempat: 'Kuningan, Jakarta (via zoom meeting)',
          Tanggal: '02 Maret 2021'},
        { id: 13,
          Nama: '"Danjen Kopassus x HIPMI Jaya PUBG Mobile Cup 2022"',
          Tempat: 'TNI AD Kopassus, Kota Jakarta',
          Tanggal: '14 Juni 2022'},
        { id: 14,
          Nama: '"BUMN Start Up Day"',
          Tempat: 'ICE BSD, Kota Tanggerang',
          Tanggal: '26 - 28 September 2022'},
        { id: 15,
          Nama: '"OJK Goes To Belitung - Pinjaman Online : Manfaat dan Resiko Bagi Masyarakat"',
          Tempat: 'BW Suite Hotel, Kota Belitung',
          Tanggal: '21 Oktober 2022'},
        { id: 16,
          Nama: '"Sosialisasi dan Edukasi BantuSaku - Mengakselerasi Pemberdayaan Ekonomi Rakyat dengan Peningkatan Literasi Keuangan & Digital"',
          Tempat: 'Asrama Haji, Kota Palembang',
          Tanggal: '24 Oktober 2022'},
        { id: 17,
          Nama: '"Go Inklusif Go Produktif - Pameran Lembaga Jasa Keuangan"',
          Tempat: 'Mall Central Park, Laguna Lobby LG Floor, Jakarta Barat',
          Tanggal: '26 - 30 Oktober 2022'},
        { id: 18,
          Nama: `"AFPI CEO's Mind - Business, Financial, and Economic Outlook 2023"`,
          Tempat: 'Hotel Indonesia Kempinski Jakarta',
          Tanggal: '19 Desember 2023'},
        { id: 19,
          Nama: `"Festival Olahraga Rekreasi Nasional (FORNAS) VII Jawa Barat - Indonesia Esports Association (IESPA) Melodies Of Victory"`,
          Tempat: 'Sudirman Grand Ballroom, Bandung',
          Tanggal: '5-6 Juli 2023'},
        { id: 20,
          Nama: `"Digital Investment Forum- P2SK Law And Digital Investment Potential in Indonesia"`,
          Tempat: 'Ballroom Bali Hotel Kempinski Jakarta',
          Tanggal: '20 Juli 2023'},
        { id: 21,
          Nama: `"AFPI Fintech Sport Days 2023"`,
          Tempat: 'Jakarta',
          Tanggal: '15 Juli - 19 Agustus 2023'},
      ],
      activeTeam: 2,
      dialogVisible: false,
      infoOptions: info.aboutInfo,
      mName: '',
      mContent: ''
    };
  },
  mounted(){
    this.$nextTick(() => {
      var wow = new WOW({
        animateClass: 'animated',
        offset: 0,
      })
      wow.init()
    })
    sessionStorage.setItem('activeTab', '3')
  },
  methods:{
    nextTeam(active){
      if(active < 4){
        this.activeTeam = active + 1
      } else {
        this.activeTeam = 2
      }
    },
    profile(index){
      this.dialogVisible = true
      this.mName = this.infoOptions[index].name
      this.mContent = this.infoOptions[index].info
    },
    show(year){
      switch(year){
        case '2020':
          window.open('https://drive.google.com/file/d/16tg4eu9aX7ZW6QE0Qp5NhN99CJ6k_uCK/view')
          break;
        case '2021':
          window.open('https://drive.google.com/file/d/1byjBdaL1QYmqCGFs-X_7BFr6Vhifu-fE/view?usp=sharing')
          break;
        case '2022':
          window.open('https://drive.google.com/file/d/1EN2EONIq6a80dHZ1eiTzYzTM2P2O6t6f/view?usp=sharing')
          break;
        case '2023':
          window.open('https://drive.google.com/file/d/1_0HyLftlQELaxjBHpdOBhFBEOPf0DooZ/view?usp=drivesdk')
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.about-wrapper{
  width: 100%;
  background: #fff;
  overflow: hidden;
  .about-team-wrap{
    width: 100%;
    min-height: 770px;
    background: url("../assets/img/team/about-banner.png") no-repeat;
    background-size: 100% 100%;
    padding: 174px 0 0 0;
    box-sizing: border-box;
    overflow: hidden;
    .about-item-box{
      max-width: 1600px;
      margin: 0 auto;
      .about-item{
        position: relative;
        .about-card{
          width: 100%;
          height: 300px;
          background: url("../assets/img/team/about-item-bg.png") no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding: 96px 0 0 40px;
          box-sizing: border-box;
          margin-bottom: 94px;
          .team-img{
            width: 158px;
            height: 158px;
            border-radius: 50%;
            position: absolute;
            left: 80px;
            top: -79px;
          }
          .team-name{
            font-size: 32px;
            font-weight: bold;
          }
          .team-position{
            font-size: 24px;
            font-weight: 500;
          }
        }
        .info-btn-card{
          width: 100%;
          position: relative;
          .about-info{
            width: 100%;
            min-height: 400px;
            background: rgba(40, 84, 63, 0.8);
            border-radius: 23px 23px 0px 0px;
            position: absolute;
            left: 0;
            bottom: 0;
            visibility: hidden;
            cursor: pointer;
            padding: 26px 20px 26px 20px;
            box-sizing: border-box;
            color: #fff;
            .about-info-title{
              font-size: 24px;
              font-weight: 600;
              margin-bottom: 10px;
            }
            .about-info-content{
              font-size: 12px;
              line-height: 25px;
            }
          }
          .info-btn{
            padding: 28px 104px;
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            border: 1px solid #000000;
            cursor: pointer;
          }
        }
        .info-btn-card:hover .about-info{
            visibility: visible;
        }
      }
    }
  }
  .m-about-wrap{
    width: 100%;
    min-height: 310px;
    background: url("../assets/img/team/m-about-banner.png") no-repeat;
    background-size: 100% 100%;
    padding: 90px 26px 36px 26px;
    box-sizing: border-box;
    .m-about-card{
      width: 90%;
      min-height: 180px;
      background: url("../assets/img/team/m-about-item-bg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 55px 100px 21px 46px;
      box-sizing: border-box;
      .m-team-img{
        width: 100px;
        height: 100px;
        position: absolute;
        top: -70px;
        left: 56px;
        border-radius: 50%;
      }
      .m-next-btn{
        width: 32px;
        height: 32px;
        position: absolute;
        right: -33px;
        top: 50%;
        cursor: pointer;
      }
      .m-team-name{
        font-size: 20px;
        font-weight: bold;
        padding-left: 10px;
        box-sizing: border-box;
        line-height: 20px;
      }
      .m-team-position{
        font-size: 12px;
        font-weight: 600;
        padding-left: 10px;
        box-sizing: border-box;
        margin: 4px 0 12px 0;
      }
      .m-team-btn{
        max-width: 128px;
        font-size: 12px;
        font-weight: 600;
        padding: 12px 44px;
        border: 1px solid #000;
        box-sizing: border-box;
        cursor: pointer;
      }

    }
  }

  .m-dialog-box{
    .m-dialog{
      width: 100%;
      min-height: 300px;
      background: url("../assets/img/team/m-dialog-bg.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 18px!important;
      padding: 45px 20px 40px 20px;
      box-sizing: border-box;
      position: relative;
      color: #000;
      top: 30% !important;
      .m-close-btn{
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10px;
        top: -36px;
      }
      .m-dialog-name{
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .m-dialog-content{
        font-size: 12px;
        line-height: 17px;
      }
    } 
  }
  .laporan-wrap{
    width: 100%;
    padding-bottom: 80px;
    background: url("../assets/img/team/team-banner.png") no-repeat;
    background-size: 100% 100%;
    background-position: right;
    margin-top: 30px;
    box-sizing: border-box;
    .laporan-card{
      max-width: 1500px;
      margin: 0 auto;
    }
    .laporan-title{
      font-size: 58px;
      font-weight: bold;
    }
    .keuangan{
      width: 382px;
      height: 98px;
      background: #000;
      line-height: 98px;
      text-align: center;
      font-weight: bold;
      font-size: 58px;
      color: #B3E872;
      margin: 20px 0 80px 0;
    }
    .smartec{
      width: 980px;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    .lihat{
      font-size: 40px;
      font-weight: 600;
      margin: 66px 0 86px 0;
    }
    .financial-report-card{
      .financial-item{
        width: 100%;
        height: 153px;
        background: #28543F;
        border-radius: 77px;
        color: #B3E872;
        font-weight: 600;
        text-align: center;
        padding-top: 26px;
        box-sizing: border-box;
        cursor: pointer;
        transition: all 0.35s ease;
        .financial-item-title{
          font-size: 26px;
        }
        .financial-item-year{
          font-size: 36px;
          img{
            width: 46px;
            height: 28px;
          }
        }
      }
      .financial-item:hover{
        transform: translateY(5px);
      }

    }
    .financial-table-card{
      width: 100%;
      padding: 60px 60px 60px 60px;
      box-sizing: border-box;
      background: #fff;
      margin-top: 80px;
      .financial-table{
        width: 100%;
      }
    }
  }
}
</style>